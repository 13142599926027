import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useCaseStudiesData } from 'views/case-studies/constants';
import { CaseStudiesTiles } from 'components/case-studies-tiles';
import { Container } from 'components/container';
import { Stats } from 'components/stats';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { arrayOf, bool, number, object, shape, string } from 'prop-types';

const SCategoryBar = styled.div`
    display: flex;
    border: 1px solid var(--gray-suit-color);
    font-weight: 700;
    margin-bottom: 2.5rem;
    justify-content: space-between;

    ${CONSTANTS.MEDIA.max500`
      flex-direction: column;
      text-align: center;
    `}
`;

const SCategoryTitle = styled.h2`
    padding: 2rem 2rem 0.5rem;
    font-size: 2.25rem;
    font-weight: 700;
    align-self: center;

    ${CONSTANTS.MEDIA.min500`
       padding: 1.5rem;
       font-size: 1.5rem;
    `}

    ${CONSTANTS.MEDIA.min840`
        padding: 1.5rem;
        font-size: 2.25rem;
        white-space: nowrap;
    `}
`;

const SImageWrapper = styled.div`
    display: flex;
    justify-content: end;
`;

const SGatsbyImage = styled(GatsbyImage)`
    height: 100%;

    ${CONSTANTS.MEDIA.min840`
        max-width: 35rem;
    `}
`;

const SStats = styled(Stats)`
    margin-bottom: 2.5rem;
`;

export const CaseStudiesSection = ({
    projectRangeFrom,
    projectRangeTo,
    className,
    margin,
    data,
    categoryImage,
    categoryTitle,
}) => {
    const defaultData = useCaseStudiesData();
    const { formatMessage } = useIntl();

    return (
        <Container className={className} margin={margin}>
            {categoryTitle && categoryImage ? (
                <SCategoryBar>
                    <SCategoryTitle>
                        <FormattedMessage id={categoryTitle} />
                    </SCategoryTitle>
                    <SImageWrapper>
                        <SGatsbyImage
                            imgStyle={{
                                objectFit: 'cover',
                                objectPosition: 'right',
                                width: '100%',
                            }}
                            image={categoryImage}
                            alt={`${formatMessage({
                                id: categoryTitle,
                            })} software development case studies`}
                        />
                    </SImageWrapper>
                </SCategoryBar>
            ) : (
                <SStats />
            )}
            <CaseStudiesTiles
                caseStudiesData={(data.length > 0 && data) || defaultData}
                projectRangeFrom={projectRangeFrom}
                projectRangeTo={projectRangeTo}
            />
        </Container>
    );
};

CaseStudiesSection.propTypes = {
    categoryTitle: string,
    categoryImage: string,
    projectRangeFrom: number,
    projectRangeTo: number,
    className: string,
    margin: bool,
    data: arrayOf(
        shape({
            title: string,
            tags: arrayOf(string),
            image: object,
            to: string,
        }),
    ),
};

CaseStudiesSection.defaultProps = {
    categoryTitle: '',
    categoryImage: '',
    projectRangeFrom: 0,
    projectRangeTo: 3,
    className: '',
    margin: true,
    data: [],
};
